import React from "react";
import { Button } from "@material-ui/core";

import { Modal } from "react-bootstrap";
import "../assets/css/components/confirmationPopup.css";
function ConfirmationDelete({ onHide, show, name, confirm }) {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="dialog"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Deletar conta do cliente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Tem certeza que deseja remover a empresa {name}?</p>
        <p>Esta ação não poderá ser desfeita.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancelar</Button>
        <Button onClick={confirm}>Sim</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationDelete;
