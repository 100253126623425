import React, { useContext, useState } from "react";
import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";

import { Modal } from "react-bootstrap";
import "../assets/css/components/confirmationPopup.css";
import useErrors from "../hooks/useErrors";
import { validateGeneric } from "../utils/validations";
import requestAdapter from "../api/request";
import SnackbarContext from "../contexts/Snackbar";
import { useEffect } from "react";
function UpdatePixInformation({ onHide, show, client, confirm }) {
  const [loading, setLoading] = useState(false);
  const [bankName, setBankName] = useState(client ? client.bankName : null);
  const [bankAliasPix, setBankAliasPix] = useState(
    client ? client.bankAliasPix : null
  );

  const [expirationTimeQRCodePix, setExpirationTimeQRCodePix] = useState();
  const [mediatorFeePixIn, setMediatorFeePixIn] = useState();
  const [mediatorFeePixOut, setMediatorFeePixOut] = useState();
  const [mediatorFeePixBilling, setMediatorFeePixBilling] = useState();
  const [maxTimeToReturn, setMaxTimeToReturn] = useState();

  const validations = {
    bankName: validateGeneric,
    bankAliasPix: validateGeneric,
    expirationTimeQRCodePix: validateGeneric,
    mediatorFeePixIn: validateGeneric,
    mediatorFeePixOut: validateGeneric,
    mediatorFeePixBilling: validateGeneric,
    maxTimeToReturn: validateGeneric,
  };
  const [errors, checkFields, canSubmit] = useErrors(validations);
  const { setSnack } = useContext(SnackbarContext);

  useEffect(() => {
    if (show && client) {
      setBankName(client.bankName);
      setBankAliasPix(client.bankAliasPix);
      setExpirationTimeQRCodePix(Number(client.expirationTimeQRCodePix));
      setMediatorFeePixIn(Number(client.mediatorFeePixIn));
      setMediatorFeePixOut(Number(client.mediatorFeePixOut));
      setMediatorFeePixBilling(Number(client.mediatorFeePixBilling));
      setMaxTimeToReturn(Number(client.maxTimeToReturn));
    } else {
      setBankName(null);
      setBankAliasPix(null);
      setExpirationTimeQRCodePix(null);
      setMediatorFeePixIn(null);
      setMediatorFeePixOut(null);
      setMediatorFeePixBilling(null);
      setMaxTimeToReturn(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const submit = async () => {
    if (
      canSubmit() &&
      bankName &&
      bankAliasPix &&
      bankAliasPix.length > 0 &&
      bankName.length > 0
    ) {
      setLoading(true);

      try {
        const json = {
          bankName,
          bankAliasPix,
          expirationTimeQRCodePix,
          mediatorFeePixOut,
          mediatorFeePixIn,
          mediatorFeePixBilling,
          maxTimeToReturn,
          clientId: client.id,
        };
        await requestAdapter.post("/admin/setPix", json);
        setLoading(false);
        setSnack({ open: true, message: "Dados PIX atualizados com sucesso!" });
        confirm();
      } catch (error) {
        // values.errorMessage = error.message;
        // values.hasError = true;
        // setValues({ ...values });
        setSnack({ open: true, message: error.message, severity: "error" });
      }

      setLoading(false);
    } else {
      setSnack({
        message: "Preencha corretamente todos os campos",
        open: true,
        severity: "error",
      });
    }
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="dialog"
      centered
      scrollable={true}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Atualizar Informação do PIX
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Informe abaixo os dados da chave PIX da empresa{" "}
          <b>
            {client?.name} ({client?.taxId})
          </b>{" "}
          para realização das transferências e as taxas desse cliente.
        </p>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            submit();
          }}
          className="formContent"
        >
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                type="text"
                value={bankName}
                onChange={(ev) => setBankName(ev.target.value)}
                variant="outlined"
                label="Nome do banco"
                name="bankName"
                disabled={loading}
                error={!errors.bankName.valid}
                helperText={errors.bankName.text}
                onBlur={checkFields}
                required
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                type="text"
                value={bankAliasPix}
                onChange={(ev) => setBankAliasPix(ev.target.value)}
                variant="outlined"
                label="Chave PIX"
                name="bankAliasPix"
                disabled={loading}
                error={!errors.bankAliasPix.valid}
                helperText={errors.bankAliasPix.text}
                onBlur={checkFields}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={expirationTimeQRCodePix}
                onChange={(ev) => setExpirationTimeQRCodePix(ev.target.value)}
                variant="outlined"
                name="expirationTimeQRCodePix"
                label="Tempo QRCode em seg."
                disabled={loading}
                required
                error={!errors.expirationTimeQRCodePix.valid}
                helperText={errors.expirationTimeQRCodePix.text}
                onBlur={checkFields}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={maxTimeToReturn}
                onChange={(ev) => setMaxTimeToReturn(ev.target.value)}
                variant="outlined"
                name="maxTimeToReturn"
                label="Tempo máximo para estorno PIX em seg."
                disabled={loading}
                required
                error={!errors.maxTimeToReturn.valid}
                helperText={errors.maxTimeToReturn.text}
                onBlur={checkFields}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={mediatorFeePixIn}
                onChange={(ev) => setMediatorFeePixIn(ev.target.value)}
                variant="outlined"
                name="mediatorFeePixIn"
                label="Taxa PIX entrada"
                disabled={loading}
                required
                error={!errors.mediatorFeePixIn.valid}
                helperText={errors.mediatorFeePixIn.text}
                onBlur={checkFields}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={mediatorFeePixOut}
                onChange={(ev) => setMediatorFeePixOut(ev.target.value)}
                variant="outlined"
                name="mediatorFeePixOut"
                label="Taxa PIX saída"
                disabled={loading}
                required
                error={!errors.mediatorFeePixOut.valid}
                helperText={errors.mediatorFeePixOut.text}
                onBlur={checkFields}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="number"
                value={mediatorFeePixBilling}
                onChange={(ev) => setMediatorFeePixBilling(ev.target.value)}
                variant="outlined"
                name="mediatorFeePixBilling"
                label="Taxa PIX boleto"
                disabled={loading}
                required
                error={!errors.mediatorFeePixBilling.valid}
                helperText={errors.mediatorFeePixBilling.text}
                onBlur={checkFields}
              />
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <CircularProgress></CircularProgress>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
        <p style={{ fontSize: 12, textAlign: "center", marginTop: 5 }}>
          * Lembre-se, a conta deve informada deve ter a mesma titularidade da
          conta cadastrada em nosso sistema, com o mesmo CNPJ.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={submit} disabled={loading}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdatePixInformation;
