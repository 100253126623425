import { useState } from "react";

function useErrors(validations) {
  const [errors, setErrors] = useState(init(validations));

  function checkFields(event, secondField = null) {
    const { name, value } = event.target;
    console.log(name, value);
    const newState = { ...errors };
    if (secondField) 
      newState[name] = validations[name](value, secondField);
    else
      newState[name] = validations[name](value);
    setErrors(newState);
  }

  function canSubmit() {
    for (let field in errors) {
      if (!errors[field].valid) return false;
    }
    return true;
  }

  return [errors, checkFields, canSubmit];
}

function init(validations) {
  const initialState = {};
  for (let field in validations)
    initialState[field] = { valid: true, text: "" };
  return initialState;
}

export default useErrors;
