import React, { useState } from "react";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  TablePagination,
  TableContainer,
  Paper,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { TitleForTables } from "./TitleForTables";
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primeira página"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="página anterior"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="próxima página"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const TableTransaction = ({ filteredRows }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  function formatDate(d) {
    let date = new Date(d)
    date = date.setHours(date.getHours() -3)
    
    d = new Date(date).toISOString();
    if (!d) return "";
    const x = d.split("T");
    const data = x[0].split("-");
    return `${data[2]}/${data[1]}/${data[0]} ${x[1].split(".")[0]}`;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const convertType = (type) => {
    if (type === "InstantPayment") {
      return "Crédito via PIX";
    } else if (type === "WITHDRAWINSTANTPAYMENT") {
      return "Saque via PIX";
    }
    return "";
  };

  const convertStatus = (status) => {
    switch (status) {
      case "CREATED":
        return "Criado";
      case "APPROVED":
        return "Aprovado";
      case "REJECT":
        return "Rejeitado";
      case "CANCELING":
        return "Cancelando";
      case "CANCELED":
        return "Cancelado";
      case "EXPIRED":
        return "Expirado";
      case "ERROR":
        return "Erro";
      case "AUTHORIZED":
        return "Autorizado";
      case "CAPTURED":
        return "Capturado";
      default:
        return ""
    }
  };

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <TitleForTables title="Detalhamento das transações" />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Data</TableCell>
              <TableCell align="left">Data retorno matera</TableCell>
              <TableCell align="left">Transaction ID</TableCell>
              <TableCell align="left">Cliente</TableCell>
              <TableCell align="left">Tipo</TableCell>
              <TableCell align="left">Descrição</TableCell>
              <TableCell align="left">Status</TableCell>

              <TableCell align="right">Valor</TableCell>
              <TableCell align="right">Taxa</TableCell>
              {/* <TableCell align="right"></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{formatDate(row.createdAt)}</TableCell>
                <TableCell align="left">{row.createdAt === row.updatedAt ? '--' : formatDate(row.updatedAt)}</TableCell>
                <TableCell align="left">{row.transactionId}</TableCell>
                <TableCell align="left">{row.clientName}</TableCell>
                <TableCell align="left">
                  {row.type === "C"
                    ? "Entrada"
                    : row.type === "D"
                    ? "Saída"
                    : "Saldo"}
                </TableCell>
                <TableCell align="left">
                  {convertType(row.transactionType)}
                </TableCell>
                <TableCell align="left">{convertStatus(row.status)}</TableCell>

                <TableCell
                  align="right"
                  // style={
                  //   row.type === "C"
                  //     ? { color: "green" }
                  //     : row.type === "D"
                  //     ? { color: "red" }
                  //     : { textWeight: "bold" }
                  // }
                >
                  {(row.type === "C" ? "+ " : row.type === "D" ? "- " : " ") +
                    `R$ ${parseFloat(row.paidAmount).toFixed(2)}`}
                </TableCell>
                <TableCell
                  align="right"
                  // style={
                  //   row.type === "C"
                  //     ? { color: "green" }
                  //     : row.type === "D"
                  //     ? { color: "red" }
                  //     : { textWeight: "bold" }
                  // }
                >
                  {(row.type === "C" ? "+ " : row.type === "D" ? "- " : " ") +
                    `R$ ${parseFloat(row.fee).toFixed(2)}`}
                </TableCell>
                {/* <TableCell>
                {(row.type === "C" || row.type === "D") && (
                  <IconButton
                    aria-label="Sair do sistema"
                    color="inherit"
                    onClick={() => handleClickOpen(row.transactionId)}
                  >
                    <Visibility />
                  </IconButton>
                )}
              </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: "Todos", value: -1 }]}
                colSpan={3}
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Linhas por página" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Linhas por página"}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
