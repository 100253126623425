import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import Mail from "@material-ui/icons/Mail";
import Lock from "@material-ui/icons/Lock";
import useErrors from "../../hooks/useErrors";
import SnackbarContext from "../../contexts/Snackbar";

import "../../assets/css/pages/home.css";
import "../../assets/css/pages/login.css";

import Logo from "../../assets/img/logo.png";
import LogoPix from "../../assets/img/pix-logo.svg";
import Bg from "../../assets/img/arte-bg-home.svg";

import { validatePassword, validateEmail } from "../../utils/validations";
import DoubleDiv from "../../components/DoubleDiv";
import ButtonRounded from "../../components/ButtonRounded";
import requestAdapter from "../../api/request";

function Login() {
  const { state } = useLocation();

  const { setSnack } = useContext(SnackbarContext);
  const history = useHistory();
  const validations = { email: validateEmail, password: validatePassword };
  const [errors, checkFields, canSubmit] = useErrors(validations);
  const [values, setValues] = useState({
    email: "",
    password: "",
    loading: false,
    hasError: false,
    errorMessage: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  async function submit() {
    if (canSubmit()) {
      values.loading = true;
      values.hasError = false;
      setValues({ ...values });

      try {
        const json = {
          email: values.email,
          password: values.password,
        };
        await requestAdapter.post("/admin/login", json);
        login();
      } catch (error) {
        values.errorMessage = error.message;
        values.hasError = true;
        setValues({ ...values });
      }

      values.loading = false;
      setValues({ ...values });
    }
  }

  function login() {
    setSnack({ message: "Seja Bem Vindo!", open: true });
    history.replace(state?.from || "/dashboard", {});
  }

  return (
    <main className="mainHome">
      <Grid container spacing={0}>
        <Grid item xs={6} className="leftsideHome">
          <img alt="Logo ControBank" src={Logo} className="logoHome" />
          <Container
            space={4}
            maxWidth="sm"
            style={{
              "textAlign": "center",
              position: "relative",
              height: "100%",
            }}
          >
           
            <DoubleDiv values={values} top={'calc(50% - 150px)'}>
            <Typography style={{marginBottom: 10, fontWeight: 'bold'}}>
              Painel Administrativo
            </Typography>
              <form
                onSubmit={(ev) => {
                  ev.preventDefault();
                  submit();
                }}
                className="formContent"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      variant="outlined"
                      label="Email"
                      required
                      name="email"
                      error={!errors.email.valid}
                      helperText={errors.email.text}
                      onBlur={checkFields}
                      disabled={values.loading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Mail />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      style={{ marginTop: "20px" }}
                      fullWidth
                      type="password"
                      value={values.password}
                      onChange={handleChange("password")}
                      variant="outlined"
                      label="Senha"
                      required
                      name="password"
                      error={!errors.password.valid}
                      helperText={errors.password.text}
                      onBlur={checkFields}
                      disabled={values.loading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <Typography className="esqueciMinhaSenha" variant="body2">
                      <Link to="/forgot-password">Esqueci minha senha</Link>
                    </Typography> */}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <ButtonRounded
                      disabled={values.loading}
                      type="submit"
                      style={{ marginTop: "20px" }}
                      text={"Entrar"}
                    ></ButtonRounded>
                  </Grid>
                </Grid>
              </form>
            </DoubleDiv>
          </Container>
        </Grid>
        <Grid item xs={6} className="rightsideHome">
          <img alt="Logo PIX" src={LogoPix} className="logoPixHome" />
          <img alt="background" src={Bg} className="backgroundHome" />
        </Grid>
      </Grid>
    </main>
  );
}

export default Login;
