import React, { useContext, useState } from "react";
import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";

import { Modal } from "react-bootstrap";
import "../assets/css/components/confirmationPopup.css";
import useErrors from "../hooks/useErrors";
import {
  validatePassword,
  validatePassword2,
} from "../utils/validations";
import requestAdapter from "../api/request";
import SnackbarContext from "../contexts/Snackbar";
import { useEffect } from "react";
function UpdateClientPassword({ onHide, show, client, confirm }) {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const validations = {
    password: validatePassword,
    confirmPassword: validatePassword2,
  };
  const [errors, checkFields, canSubmit] = useErrors(validations);
  const { setSnack } = useContext(SnackbarContext);

  useEffect(() => {
    if (show) {
      setPassword(null);
      setConfirmPassword(null);
    }
  }, [show]);

  const submit = async () => {
    if (canSubmit() && password && confirmPassword) {
      setLoading(true);

      try {
        const json = {
          password,
          clientId: client.id,
        };
        await requestAdapter.post("/admin/updatePassword", json);
        setLoading(false);
        setSnack({ open: true, message: "Senha atualizada com sucesso!" });
        confirm();
      } catch (error) {
        setSnack({ open: true, message: error.message, severity: "error" });
      }

      setLoading(false);
    } else {
      setSnack({
        message: "Preencha corretamente todos os campos",
        open: true,
        severity: "error",
      });
    }
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="dialog"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Atualizar senha do cliente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Informe abaixo a nova senha para acesso a conta do cliente:{" "}
          <b>
            {client?.name ?? ""} - {client?.taxId ?? ""}
          </b>
        </p>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            submit();
          }}
          className="formContent"
        >
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                type="password"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                variant="outlined"
                name="password"
                label="Senha"
                disabled={loading}
                required
                error={!errors.password.valid}
                helperText={errors.password.text}
                onBlur={checkFields}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                type="password"
                value={confirmPassword}
                onChange={(ev) => setConfirmPassword(ev.target.value)}
                variant="outlined"
                name="confirmPassword"
                label="Confirmação de senha"
                disabled={loading}
                required
                error={!errors.confirmPassword.valid}
                helperText={errors.confirmPassword.text}
                onBlur={(ev) => checkFields(ev, password)}
              />
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <CircularProgress></CircularProgress>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
        {/* <p style={{ fontSize: 12, textAlign: "center", marginTop: 5 }}>
          * Lembre-se, a transferência será realizada na hora informada e 59 minutos.
        </p> */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={submit} disabled={loading}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateClientPassword;
