import React, { useState, useEffect, useContext } from "react";
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import requestAdapter from "../../api/request";

import SnackbarContext from "../../contexts/Snackbar";
import { Alert } from "@material-ui/lab";
import ButtonRounded from "../../components/ButtonRounded";
import { TableTransaction } from "../../components/TableTransaction";
import { TableTransactionInfo } from "../../components/TableTransactionInfo";

function Settings() {
  const [looked, setLooked] = useState(false);
  const { setSnack } = useContext(SnackbarContext);

  const [loading] = useState(false);
  const [error] = useState({ hasError: false, errorMessage: "" });
  const [balanceData, setBalanceData] = useState();
  const [initalDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [input, setInput] = useState(true);
  const [output, setOutput] = useState(true);
  const [client, setClient] = useState(-1);
  const [users, setUsers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionsInfo, setTransactionsInfo] = useState([]);
  const [loadingWithDraw, setLoadingWithDraw] = useState(false);


  useEffect(() => {
    
    getBalanceData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getBalanceData() {
      setLooked(false);
      try {
        const res = await requestAdapter.get(`/admin/balance`);
        if (res.status === 200) {
          setBalanceData(res.data.data);
        }
      } catch (ex) {
        setSnack({
          message: "Ocorreu um erro, tente novamente",
          open: true,
          severity: "error",
        });
      }
      setLooked(true);
  }

  function twoPlaces(number) {
    number = Number(number);
    if (number < 10) return "0" + number;
    return number;
  }

  useEffect(() => {
    const endDate = new Date();
    const final =   `${endDate.getFullYear()}-${twoPlaces(
      endDate.getMonth() + 1
    )}-${twoPlaces(endDate.getDate())}`
    
    
    setFinalDate(final);

    
    endDate.setDate(endDate.getDate() - 30);
    const initial =
    `${endDate.getFullYear()}-${twoPlaces(
      endDate.getMonth() + 1
    )}-${twoPlaces(endDate.getDate())}`
    setInitialDate(initial);
    getClients();
    search(initial, final);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getClients() {
    try {
      const res = await requestAdapter.get(`/admin/clients`);
      const ans = [];
      for (const user of res.data.users) {
        ans.push({ value: user.id, name: user.name + " (" + user.taxId + ")" });
      }
      setUsers(ans);
    } catch (ex) {
      setSnack({
        message: "Ocorreu um erro, tente novamente",
        open: true,
        severity: "error",
      });
    }
    setLooked(true);
  }

  function convertDate(date) {
    
    return (
      date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0]
    );
  }

  async function search(a = null, b = null) {
    try {
      
      let params =
        "initialDate=" +
        convertDate(a ? a : initalDate) +
        "&&finalDate=" +
        convertDate(b ? b : finalDate);
      if (client && client !== -1) {
        params += "&client=" + client;
      }
      if (input && !output) {
        params += "&type=C";
      } else if (!input && output) {
        params += "&type=D";
      }
      const res = await requestAdapter.get(`/admin/clients-info/?${params}`);
      const transactions = res.data.transactions;
      // console.log(transactions);
      setTransactions(transactions);
      buildInformationsPerClient(transactions);
    } catch (ex) {
      console.log(ex);
      setSnack({
        message: "Ocorreu um erro, tente novamente",
        open: true,
        severity: "error",
      });
    }
  }

  const sumAll = (type) => {
    let val = 0;
    for (const t of transactions) {
      if (t.type === type) val += Number(t.paidAmount);
    }
    return val.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  };

  const sumAllFee = (type) => {
    let val = 0;
    for (const t of transactions) {
      if (t.type === type) val += Number(t.fee);
    }
    return val.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  };

  const ticket = (type) => {
    const op = transactions.filter((value) => value.type === type).length;
    if (!op) return "R$ 0,00";
    let val = 0;
    for (const t of transactions) {
      if (t.type === type) val += Number(t.paidAmount);
    }
    return (val / op).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const sumAllWithTransactions = (type, transactions) => {
    let val = 0;
    for (const t of transactions) {
      if (t.type === type) val += Number(t.paidAmount);
    }
    return val.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  };

  const sumAllWithTransactionsCreditDebit = (transactions) => {
    let val = 0;
    for (const t of transactions) {
      val += Number(t.paidAmount);
    }
    return val.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  };

  const sumAllFeeTransactions = (type, transactions) => {
    let val = 0;
    for (const t of transactions) {
      if (t.type === type) val += Number(t.fee);
    }
    return val.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  };

  const sumAllFeeTransactionsCreditDebit = (transactions) => {
    let val = 0;
    for (const t of transactions) {
      val += Number(t.fee);
    }
    return val.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  };

  const ticketTransactions = (type, transactions) => {
    const op = transactions.filter((value) => value.type === type).length;
    if (!op) return "R$ 0,00";

    let val = 0;
    for (const t of transactions) {
      if (t.type === type) val += Number(t.paidAmount);
    }

    return (val / op).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const buildInformationsPerClient = (transactions) => {
    const clients = {};
    for (const t of transactions) {
      if (!t || !t.accountId) continue;
      if (!clients[t.accountId]) {
        clients[t.accountId] = [];
      }
      clients[t.accountId].push(t);
    }
    const ans = [];
    for (const c in clients) {
      if (!clients[c].length) continue;
      const data = {
        clientName: clients[c][0].clientName,
        CNPJ: clients[c][0].CNPJ,
        in: {
          number: clients[c].filter((value) => value.type === "C").length,
          value: sumAllWithTransactions("C", clients[c]),
          fee: sumAllFeeTransactions("C", clients[c]),
          ticket: ticketTransactions("C", clients[c]),
        },
        out: {
          number: clients[c].filter((value) => value.type === "D").length,
          value: sumAllWithTransactions("D", clients[c]),
          fee: sumAllFeeTransactions("D", clients[c]),
          ticket: ticketTransactions("D", clients[c]),
        },
        fee: sumAllFeeTransactionsCreditDebit(clients[c]),
        value: sumAllWithTransactionsCreditDebit(clients[c]),
      };

      ans.push(data);
    }
    setTransactionsInfo(ans);
    return ans;
  };

  async function withDraw() {
    setLoadingWithDraw(true);
    try {
      await requestAdapter.get(`/admin/withdraw`);
      getBalanceData();
      setSnack({
        message: "Saque realizado com sucesso!",
        open: true,
        severity: "success",
      });
    } catch (ex) {
      setSnack({
        message: "Ocorreu um erro, tente novamente",
        open: true,
        severity: "error",
      });
    }
    setLoadingWithDraw(false);
  }

  return (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{
          marginTop: "25px",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{ borderRadius: "25px", borderWidth: 1, borderColor: "black" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Informações úteis</Typography>
              <Divider
                spacing={4}
                style={{
                  background: "var(--color-orange-mid)",
                  width: "20%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <Typography>
                {!looked && <div>Carregando suas informações...</div>}
                {looked && balanceData && (
                  <div>
                    <div>Saldo disponível em conta:{" "}
                    <b
                      style={{ fontSize: 20, color: "var(--color-orange-mid)" }}
                    >
                      {balanceData.available.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </b>
                    </div>
                    {loadingWithDraw === false && <span onClick={withDraw} style={{color: "var(--color-orange-mid)", textDecorationLine: 'underline', cursor: 'pointer'}}>Realizar saque</span>}
                    {loadingWithDraw && <CircularProgress size={24}></CircularProgress>}
                  </div>
                  
                )}
              </Typography>
            </Grid>
            <Divider
              spacing={4}
              style={{
                background: "var(--color-orange-mid)",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 10,
                marginBottom: 20,
              }}
            />
            <Grid
              justify="center"
              container
              spacing={1}
              style={{ marginBottom: 10, alignItems: "center" }}
            >
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  id="initialDate"
                  value={initalDate}
                  onChange={(ev) => {setInitialDate(ev.target.value); }}
                  label="Data inicial"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  value={finalDate}
                  size="small"
                  variant="outlined"
                  id="finalDate"
                  onChange={(ev) => {setFinalDate(ev.target.value); }}
                  label="Data final"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item>
                <b style={{ fontWeight: "bold" }}>Filtrar por:</b>
                <FormControlLabel
                  style={{ paddingLeft: 10 }}
                  control={
                    <Checkbox
                      variant="outlined"
                      checked={input}
                      color="primary"
                      name="pagos"
                      onChange={() => setInput(!input)}
                    />
                  }
                  label="Entradas"
                />
                <FormControlLabel
                  style={{ paddingLeft: 10 }}
                  control={
                    <Checkbox
                      variant="outlined"
                      checked={output}
                      color="primary"
                      name="naopagos"
                      onChange={() => setOutput(!output)}
                    />
                  }
                  label="Saídas"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center" justify="center">
              <Grid item xs={6}>
                <Select
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={client}
                  label="Cliente"
                  onChange={(ev) => setClient(ev.target.value)}
                >
                  <MenuItem value={-1}>
                    <em>Todos os clientes</em>
                  </MenuItem>
                  {users.map((user) => {
                    return (
                      <MenuItem value={user.value} key={user.value}>
                        {user.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item style={{ justifyContent: "flex-end" }}>
                <ButtonRounded onClick={() => search()} text={"Buscar"}></ButtonRounded>
              </Grid>
            </Grid>
            <Divider
              spacing={4}
              style={{
                background: "var(--color-orange-mid)",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 10,
              }}
            />
          </Grid>
        </div>
        {error.hasError && (
          <Grid container justify="center">
            <Grid item xs={12} sm={4}>
              <Alert severity="warning" style={{ marginTop: "20px" }}>
                {error.errorMessage}
              </Alert>
            </Grid>
          </Grid>
        )}
      </Grid>
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          display: "flex",
          gap: 20,
          alignItems: "center",
          marginTop: 40,
          marginBottom: 20,
        }}
      >
        {[
          { description: "entradas", title: "Entradas", type: "C" },
          { description: "saídas", title: "Saídas", type: "D" },
        ].map((item, key) => {
          return (
            <div
              key={key}
              style={{
                background: "white",
                borderRadius: 25,
                borderColor: "var(--color-orange)",
                border: "1px solid",
                boxShadow: "10px 10px 5px 1px var(--color-gray)",
                padding: 10,
                flex: 1,
              }}
            >
              <h5
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 26,
                  color: "var(--color-orange-light)",
                }}
              >
                {item.title}
              </h5>
              <Card
                title={`Nº de ${item.description}`}
                value={
                  transactions.filter((value) => value.type === item.type)
                    .length
                }
              />
              <Card
                title={`Valor de ${item.description}`}
                value={sumAll(item.type)}
              />
              <Card
                title={`Taxas de ${item.description}`}
                value={sumAllFee(item.type)}
              />
              <Card
                title={`Ticket médio de ${item.description}`}
                value={ticket(item.type)}
              />
            </div>
          );
        })}
      </div>

      <TableTransactionInfo filteredRows={transactionsInfo} />
      <TableTransaction filteredRows={transactions} />

      <Grid
        container
        spacing={0}
        justify="center"
        style={{ marginTop: "10px" }}
      >
        {loading && (
          <Grid item xs={3}>
            <CircularProgress></CircularProgress>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Settings;

const Card = ({ title, value }) => {
  return (
    <div style={{ flex: 1 }}>
      <span
        style={{
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        {title}:
      </span>

      <span style={{ fontSize: 24, marginLeft: 10 }}>{value}</span>
    </div>
  );
};
